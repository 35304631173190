<template>
  <div :style="{ 'height': loading ? '100%' : '95vh' }">
    <div v-if="devError || apiError" class="d-panel-body d-flex justify-content-center" style="padding: 0;">
      <div class="d-flex">
        <div class="w-100 h-100 d-flex justify-content-center" style="border-radius: 10px;">
          <div class="display-area p-3 p-sm-5" style="max-height: 90vh; overflow-y: auto">
            <div v-if="devError" class="">
              <template v-if="devError === 'LOGIN_SUCCESS'">
                <h2>Login successful</h2>
                <div>
                  The login was completed successfully, but a successful redirect location to the app was not specified.
                  <br><br>
                  To complete the login flow, navigate back to the app, it should correctly log you in.
                  <br><br>
                  <div class="d-flex">
                    <b-button @click="returnToApp" variant="outline-primary" class="mr-3">Back to app</b-button>
                  </div>
                  <br>
                  <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
                </div>
              </template>

              <template v-else-if="devError === 'CONNECTION_TEST'">
                <h2>Login test successful</h2>
                <div>
                  The login was completed successfully for this connection test. This indicates the that the connection is correctly configured, you may close this tab.
                  <br><br>Connection configuration: <span class="text-success">Complete</span>
                  <br>Authorization code: <span class="text-success">Verified</span>
                  <br>Code exchange: <span class="text-success">Success</span>

                  <br><br>Provider's response:<br>
                  <pre><code>{{ stringifyResponse }}</code></pre>
                  <br>
                  Authress generated tokens:<br>
                  <pre><code>{{ errorResponse }}</code></pre>
                  <br>
                  <div class="d-flex">
                    <b-button href="https://authress.io/app/#/setup?focus=connections" variant="outline-primary" class="mr-3">Authress connection setup</b-button>
                  </div>
                </div>
              </template>

              <template v-else-if="devError === 'CUSTOM_DOMAIN_MISMATCH'">
                <h2>Login incomplete</h2>
                <div>
                  <strong>Incompatible domain</strong> specified. The domain for the application must match the custom domain.
                  <br><br>
                  Custom Domain: <span class="text-success">{{ host }}</span>
                  <br><span v-if="applicationRedirectUrl">Application Domain: <span class="text-danger">{{ getHostFromUrl(applicationRedirectUrl) }}</span></span>

                  <br><br>
                  <small>For any additional questions, please contact <a :href="`mailto:support@authress.io`">support@authress.io</a>.</small>
                </div>
              </template>

              <template v-else-if="devError === 'ACCOUNT_SETUP_REQUIRED'">
                <h2>Login incomplete</h2>
                <div>
                  <strong>Account domain setup</strong> is incomplete. For security of your app, a custom domain must be configured for application urls other than <code>localhost</code>.
                  <br><br>Navigate to the <a href="https://authress.io/app/#/setup?focus=domain">Authress Management Portal</a> to configure one.
                  <br><br>
                  <div class="d-flex">
                    <b-button href="https://authress.io/app/#/setup?focus=domain">Setup custom domain</b-button>
                  </div>
                  <br>
                  <small>Want to know more about this or if you experience any additional issues, please contact <a :href="`mailto:support@authress.io`">support@authress.io</a>.</small>
                </div>
              </template>

              <template v-else-if="devError === 'USE_CUSTOM_DOMAIN'">
                <h2>Login incomplete</h2>
                <div>
                  <strong>Account domain setup</strong> is incomplete.
                  <br>Your account contains a successfully created Authress Custom Domain, but the <code>authressApiUrl</code> configured in your Authress SDK has not been updated.
                  <br><br>Navigate to your user login configuration for the Authress Login SDK in your UI, and update it.
                  <br>
                  <pre><code>{{ errorResponse }}</code></pre>
                  <br>
                  <small>Want to know more about this or if you experience any additional issues, please contact <a :href="`mailto:support@authress.io`">support@authress.io</a>.</small>
                </div>
              </template>

              <template v-else-if="devError === 'DEV_MIXUP_ATTACK_PREVENTED'">
                <h2>Login failed</h2>
                <div>
                  Your Service Client's <strong>Custom authentication</strong> blocked this login attempt.
                  <br>The connection ID specified in the authentication request does not exactly match the service client used to generate the login url.
                  The service client and connection ID must be consistent.
                  <br><br>The solution here is to update your application SDK integration for <code>loginClient.authenticate()</code> in the Authress Login SDK
                  to specify the Service Client ID as the Connection ID:
                  <br>
                  <pre><code>{{ errorResponse }}</code></pre>
                  <br>
                  <small>Want to know more about this or if you experience any additional issues, please contact <a :href="`mailto:support@authress.io`">support@authress.io</a>.</small>
                </div>
              </template>

              <template v-else-if="devError === 'BILLING_SETUP_REQUIRED'">
                <h2 class="text-danger">Login restricted</h2>
                <div>
                  <strong>Authentication cannot be completed</strong>. The Authress account is restricted and requires additional billing configuration.
                  <br>To enable login: go to the billing portal and update the configuration.

                  <br><br>
                  <div class="d-flex">
                    <b-button href="https://authress.io/app/#/setup?focus=billing" variant="outline-primary" class="mr-3">Authress billing portal</b-button>
                  </div>

                  <br>
                  <small>For any additional questions, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
                </div>
              </template>
            </div>

            <div v-else-if="apiError === 'BUSINESS_ACCOUNT_REQUIRED'" class="col-12 d-flex justify-content-center flex-column">
              <div class="d-flex justify-content-center align-items-center flex-column">
                <h2>Login incomplete</h2>
                <span>A valid business account is required to login.<br><br></span>
                <div class="d-flex flex-wrap justify-content-center">
                  <b-button @click="returnToApp" variant="outline-primary" class="mb-2 mb-sm-0 ml-3 mr-3">Back to app</b-button>
                  <b-button @click="attemptLoginAgain" variant="outline-primary" class="mb-2 mb-sm-0">Login with another account</b-button>
                </div>
                <small><br><br>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>
            </div>

            <div v-else-if="apiError" class="col-12 col-lg-8">
              <h2>Login unsuccessful</h2>
              <div v-if="apiError === 'ISSUE_WITH_CODE'">
                The response from your login provider during log in was:<br>
                <pre><code>{{ errorResponse || stringifyResponse }}</code></pre>
                <br>
                This can occur if they are currently having a status incident or the permission access was disallowed during login. Please try again by navigating back to the app.
                <br><br>
                <div class="d-flex flex-column flex-sm-row">
                  <b-button @click="returnToApp" variant="outline-primary" class="mb-2 mb-sm-0 mr-sm-3">Back to app</b-button>
                  <b-button @click="attemptLoginAgain" variant="outline-primary">Login again</b-button>
                </div>
                <br><br>
                <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>

              <div v-else-if="apiError === 'MIXUP_ATTACK_PREVENTED'">
                We've blocked completed your selected identity provider.<br>
                <br>
                This is because your identity provider's published official OAuth configuration does match the data they just sent us.
                This is a serious problem that indicates either your identity provider has a security vulnerability
                or your current login resulted from an attacker attempting to compromise your account.
                <br><br>
                <div class="d-flex flex-column flex-sm-row">
                  <b-button @click="returnToApp" variant="outline-primary" class="mb-2 mb-sm-0 mr-sm-3">Back to app</b-button>
                </div>
                <br><br>
                <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>

              <div v-else-if="apiError === 'NO_STATE'">
                There was an incomplete login attempt, and the response from the provider is:<br><br>
                <pre><code>{{ stringifyResponse }}</code></pre>
                <br>
                The login flow was interrupted before it was able to complete or the provider failed to provide the required properties to complete the request.
                This can happen if the back button was used or an incomplete login flow caused navigation here.
                Please try again by navigating back to the app.
                <br><br>
                <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>

              <div v-else-if="apiError === 'PROVIDER_REJECTED'">
                The identity provider rejected this request during an attempt to exchange the authorization code for a valid identity.
                <br><br>Authorization code: <span class="text-success">Success</span>
                <br>Code exchange: <span class="text-danger">Failed</span>
                <br><br>
                <pre><code>{{ errorResponse }}</code></pre>
                <br><br>
                <div class="d-flex">
                  <b-button @click="returnToApp" variant="outline-primary" class="mb-2 mb-sm-0 mr-sm-3">Back to app</b-button>
                  <b-button @click="attemptLoginAgain" variant="outline-primary">Login again</b-button>
                </div>
                <br><br>
                <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>

              <div v-else>
                An unknown error occurred while attempting to exchange the authorization code for a valid identity.
                <br><br>Authorization code: <span class="text-success">Success</span>
                <br>Code exchange: <span class="text-danger">Failed</span>
                <br><br>
                <pre><code>{{ errorResponse }}</code></pre>
                <br>
                Please try again by navigating back to the app.
                <br><br>
                <div class="d-flex">
                  <b-button @click="returnToApp" variant="outline-primary" class="mb-2 mb-sm-0 mr-sm-3">Back to app</b-button>
                  <b-button @click="attemptLoginAgain" variant="outline-primary">Login again</b-button>
                </div>
                <br><br>
                <small>If you continue to experience issues, please contact <a :href="`mailto:support@${host}`">support@{{ host }}</a>.</small>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-else-if="mfaChallengeData">
      <template v-if="!forceDisplayTotp && mfaChallengeData.options.some(o => o.type === 'WebAuthN')">
        <WebAuthN :authenticationRequestId="mfaChallengeData.challengeId" :devices="mfaChallengeData.options" @complete="handleMfaChallengeResponse" @failed="handleMfaChallengeFailure"
          :authenticationProperties="authenticationProperties" :backButton="mfaChallengeData.options.some(o => o.type !== 'WebAuthN')" @back="forceDisplayTotp = true">
          <template #header>
            <h2>Provide your Security Key</h2>
          </template>
          <template #backButton>Enter Authenticator Code</template>
          <template #callToAction>Continue</template>
        </WebAuthN>
      </template>
      <template v-else-if="mfaChallengeData.options.some(o => o.type === 'TOTP')">
        <div>
          <totp-challenge-entry :authenticationRequestId="mfaChallengeData.challengeId" :authenticationProperties="authenticationProperties"
            @complete="handleMfaChallengeResponse" @failed="handleMfaChallengeFailure"
            :backButton="mfaChallengeData.options.some(o => o.type !== 'TOTP')" @back="forceDisplayTotp = false" />
        </div>
      </template>
    </template>

    <WebAuthN v-else-if="webAuthN" :enableRegistration="true" :authenticationRequestId="authResponse.state" @complete="handleLoginSuccessResponse" @failed="handleLoginFailed">
      <template #header>
        <h2>WebAuthN Passkey login</h2>
      </template>
    </WebAuthN>
    <div v-else-if="loading">
      <loader pageLoader />
    </div>

  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import WebAuthN from './webauthn/webAuthN';
import TotpChallengeEntry from './webauthn/totpChallengeEntry.vue';

import HttpClient from '../clients/httpClient';
import logger from '../clients/logger';

export default {
  name: 'LoginRedirectScreen',

  components: { BButton, WebAuthN, TotpChallengeEntry },

  data() {
    return {
      host: this.$store.getters.host,

      loading: true,
      forceDisplayTotp: false,
      mfaChallengeData: null,
      authenticationProperties: {
        flow: 'MFA_CHALLENGE_RESPONSE',
        loginCompletionType: 'MFA_CHALLENGE_RESPONSE'
      },
      apiError: null,
      devError: null,
      applicationRedirectUrl: null,
      stringifyResponse: null,
      errorResponse: null
    };
  },

  computed: {
    webAuthN() {
      return !!this.$route.query.webauthn;
    },
    authResponse() {
      const parameters = {};
      [...new URLSearchParams(window.location.hash.slice(1)).entries()].map(([k, v]) => {
        parameters[k] = v;
      });
      [...new URLSearchParams(window.location.search.slice(1)).entries()].map(([k, v]) => {
        parameters[k] = v;
      });
      return parameters;
    }
  },

  async created() {
    if (this.webAuthN) {
      return;
    }
    const response = this.authResponse;
    const code = response.code || response['openid.identity'];

    if (response['openid.identity']) {
      logger.error({ title: 'openid legacy v2 is set, we need to ensure that the signature is being verified correctly', response });
    }
    const issuer = response.iss;

    // call the login for the code, exchange on our server
    this.stringifyResponse = typeof response === 'string' ? response : JSON.stringify(response, null, 2);
    if (response.error === 'access_denied') {
      if (response.state) {
        logger.info({ title: 'User cancelled login flow, we return the user to the state specified if possible.', loginResponse: response });
      } else {
        logger.track({ title: 'User cancelled login flow, right now we just send the user back to the top level domain because we do not have a state to use at all :(, track to see how often this happens, and decide if there is anything we can do about it because it is not a good user experience to have this happen.', loginResponse: response });
      }
      this.returnToApp(null, { useLastKnownLocation: true });
      return;
    }

    if (response.error === 'invalid_request') {
      logger.track({ title: 'The login request is no longer valid, right now we just send the user back to the top level domain, but really we should grab the authenticationRequestId and use that to respond if possible, we might not have it any more especially if it is expired.', loginResponse: response });
      this.returnToApp(null, { useLastKnownLocation: true });
      return;
    }
    if (!code) {
      logger.info({ title: 'Login unsuccessful there is no code specified', loginResponse: response });
      this.apiError = 'ISSUE_WITH_CODE';
      return;
    }

    const authenticationRequestId = response.state;
    if (!authenticationRequestId) {
      logger.info({ title: 'Login unsuccessful there is no state specified', loginResponse: response });
      this.apiError = 'NO_STATE';
      return;
    }

    this.$store.commit('setAuthenticationRequest', null);
    let firstError;
    for (let retryCount = 0; retryCount < 10; retryCount++) {
      try {
        const loginResponse = await new HttpClient().patch(`/authentication/${encodeURIComponent(authenticationRequestId)}`, { code, issuer, metadata: { ...response } });
        this.handleLoginSuccessResponse(loginResponse.data);
        return;
      } catch (error) {
        if (error.status && error.status < 500) {
          if (firstError) {
            logger.error({ title: 'Login unsuccessful with hard failure after multiple tries.', non5xxErrorResult: error, first5XXError: firstError, response }, false);
          }
          await this.handleLoginFailed(error, response);
          return;
        }

        // If it is a 5XX than store the error and retry until success
        firstError = firstError || error;
        logger.warn({ title: 'Login unsuccessful due to unexpected error.', error, firstError, response }, false);
        await new Promise(resolve => setTimeout(resolve, 2 ** retryCount * 20));
      }
    }

    logger.error({ title: 'Login unsuccessful due to unexpected error.', firstError, response }, false);

    // If there is never a success fall through to the error handling with the first error we got
    await this.handleLoginFailed(firstError, response);
  },

  methods: {
    async handleMfaChallengeFailure(mfaChallengeFailure) {
      logger.error({ title: 'MFA failed', mfaChallengeFailure });
      this.$store.commit('setLastSelectedConnection', null);
      await this.returnToApp();
      return;
    },
    async handleMfaChallengeResponse(mfaChallengeResponse) {
      setTimeout(() => {
        window.location.assign(mfaChallengeResponse.redirectUrl);
      }, 10);
      // Force the spinner to stay here for 5 more seconds.
      await new Promise(resolve => setTimeout(resolve, 5000));
    },

    handleLoginSuccessResponse(response) {
      // eslint-disable-next-line no-console
      console.debug({ title: 'Login Success', cookies: document.cookie, loginResponse: response });
      if (response.redirectUrl) {
        window.location.assign(response.redirectUrl);
        return;
      }

      if (response.mfaRequired) {
        this.mfaChallengeData = response;
        this.loading = false;
        return;
      }

      if (!response.loggedIn) {
        this.devError = 'CONNECTION_TEST';
        this.errorResponse = JSON.stringify(response.response || {}, null, 2);
        this.loading = false;
        return;
      }

      this.devError = 'LOGIN_SUCCESS';
      return;
    },

    async handleLoginFailed(error, responseInUrl) {
      if (error.code === 'RETURN_TO_APP' || error.data?.errorCode === 'ProviderLoginFailed') {
        await this.returnToApp(error.data);
        return;
      }

      if (error.data?.errorCode === 'ProviderLoginFailedToComplete') {
        if (error.data?.authenticationUrl) {
          window.location.assign(error.data?.authenticationUrl);
          return;
        }
        await this.attemptLoginAgain();
        return;
      }

      if (error.data?.errorCode === 'BusinessAccountRequired') {
        const errorMessage = error.message || error.data || error;
        this.errorResponse = typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage, null, 2);
        this.apiError = 'BUSINESS_ACCOUNT_REQUIRED';
        return;
      }

      if (error.data?.errorCode === 'ProviderLoginBlocked') {
        const errorMessage = error.message || error.data || error;
        this.errorResponse = typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage, null, 2);
        this.apiError = 'PROVIDER_REJECTED';
        return;
      }

      if (error.status === 409 && error.data?.errorCode === 'Expired') {
        logger.warn({ title: 'Login unsuccessful due to authentication request has expired. Returning to app redirect url, it is possible that the user got here from hitting back in their browser, if so we can considering a different experience for them.', error, responseInUrl });
        await this.returnToApp(error.data);
        return;
      }

      if (error.status === 404 || error.status === 409) {
        logger.track({ title: 'Login unsuccessful due to authentication request conflict/does not exist. Returning to app redirect url, it is possible that the user got here from hitting back in their browser, if so we can considering a different experience for them.', error, responseInUrl });
        await this.returnToApp(error.data);
        return;
      }

      this.loading = false;

      if (error.status === 402) {
        logger.info({ title: 'Login unsuccessful: billing update required', error, responseInUrl });
        this.devError = 'BILLING_SETUP_REQUIRED';
        return;
      }
      if (error.status === 422 && error.data?.errorCode === 'CustomDomainRequired') {
        logger.info({ title: 'Login unsuccessful: custom domain required', error, responseInUrl });
        this.devError = error.data?.details?.authressCustomDomain ? 'USE_CUSTOM_DOMAIN' : 'ACCOUNT_SETUP_REQUIRED';
        this.errorResponse = `
          import { LoginClient } from '@authress/login';
          const loginClient = new LoginClient({
            authressApiUrl: 'https://${error.data?.details?.authressCustomDomain}'
          });`;

        return;
      }

      if (error.status === 422 && error.data?.errorCode === 'MixupAttackPrevented') {
        logger.info({ title: 'Login unsuccessful: due to a mixup attack prevented', error, responseInUrl });
        if (error.data.expectedTokenUrl.startsWith(window.location.origin) && error.data.expectedTokenUrl.match(/v\d+\/clients\//)) {
          this.devError = 'DEV_MIXUP_ATTACK_PREVENTED';
          this.errorResponse = `
            import { LoginClient } from '@authress/login';
            const loginClient = new LoginClient({});
            await loginClient.authenticate({ connectionId: 'con_${error.data.expectedTokenUrl.match(/[/](?:con_)?(sc_[^/]+)[/]/)?.[1]}' });`;
        } else {
          this.apiError = 'MIXUP_ATTACK_PREVENTED';
          this.errorResponse = error.data.title;
        }

        return;
      }

      logger.error({ title: 'Login unsuccessful', error, responseInUrl });
      const errorMessage = error.message || error.data || error;
      this.errorResponse = typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage, null, 2);
      this.apiError = 'UNKNOWN_ERROR';
    },

    async attemptLoginAgain() {
      this.loading = true;
      this.apiError = null;
      this.devError = null;
      const authenticationRequestId = this.authResponse?.state;
      if (authenticationRequestId) {
        try {
          const authenticationRequest = await new HttpClient().get(`/authentication/${encodeURIComponent(authenticationRequestId)}`);
          if (authenticationRequest.data.authenticationUrl) {
            window.location.assign(authenticationRequest.data.authenticationUrl);
            return;
          }
        } catch (error) {
          if (error.data?.errorCode === 'ExpiredRequest') {
            logger.warn({ title: 'Retry login failed due to ExpiredRequest', error, loginResponse: this.authResponse, errorResponse: this.errorResponse || this.stringifyResponse }, false);
          } else {
            logger.error({ title: 'Retry login failed', error, loginResponse: this.authResponse, errorResponse: this.errorResponse || this.stringifyResponse }, false);
          }
        }
      }

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.assign(appLocation);
    },

    async returnToApp(errorData, options = { useLastKnownLocation: false }) {
      this.loading = true;
      this.apiError = null;
      this.devError = null;

      if (errorData?.redirectUrl) {
        logger.warn({ title: 'Redirecting to app location via forced redirect from error data', errorData });
        window.location.assign(errorData.redirectUrl);
        return;
      }

      const authenticationRequestId = this.authResponse?.state;
      if (authenticationRequestId) {
        try {
          const authenticationRequest = await new HttpClient().get(`/authentication/${encodeURIComponent(authenticationRequestId)}`);
          const returnUrl = options?.useLastKnownLocation && authenticationRequest.data.lastKnownLocationUrl || authenticationRequest.data.redirectUrl;
          if (returnUrl) {
            logger.log({ title: 'Redirecting to app location via redirect', appLocation: returnUrl });
            window.location.assign(returnUrl);
            return;
          }
        } catch (error) {
          if (error.status === 400 && error.data?.errorCode === 'ExpiredRequest') {
            logger.log({ title: 'Redirecting to app location via redirect from expired request', appLocation: error.data.redirectUrl });
            window.location.assign(error.data.redirectUrl);
            return;
          }
          if (error.status !== 404) {
            logger.error({ title: 'Retry navigate back to app failed', error, loginResponse: this.authResponse }, false);
          }

          logger.warn({ title: 'Retry navigate back to app failed because auth request does not exist.', error, loginResponse: this.authResponse }, false);
        }
      }

      const appLocation = window.location.origin.replace(window.location.hostname, this.host);
      logger.log({ title: 'Redirecting to app location', appLocation });
      window.location.assign(appLocation);
    },

    getHostFromUrl(url) {
      try {
        return new URL(url).host;
      } catch (error) {
        return url;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
code {
  word-break: break-all;
}
</style>
